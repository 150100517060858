import { Box, Button, darken, IconButton, lighten, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
// eslint-disable-next-line no-restricted-imports
import { SnackbarContent } from "notistack";
import { forwardRef, ReactNode, useMemo } from "react";
import { UIAction } from "../types/uiActions";
import { UIActionButton } from "./UIActionButton";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: 4,
      maxWidth: 420,
      minWidth: 100,
      paddingBottom: theme.spacing(1),
    },
    close: {
      position: "absolute",
      top: theme.spacing(0.25),
      right: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    closeIcon: {
      width: 22,
      height: 22,
    },
    content: {
      padding: theme.spacing(1.5, 3, 0.5, 2),
      ...theme.typography.body2,
    },
    dismissContentPadding: {
      paddingRight: theme.spacing(6),
    },
    defaultColors: {
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
    errorColors: {
      backgroundColor: lighten(theme.palette.error.light, 0.8),
      color: theme.palette.error.dark,
    },
    warningColors: {
      backgroundColor: lighten(theme.palette.warning.light, 0.7),
      color: darken(theme.palette.warning.dark, 0.3),
    },
    successColors: {
      backgroundColor: lighten(theme.palette.success.light, 0.7),
      color: darken(theme.palette.success.dark, 0.3),
    },
    actionsWrap: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      width: "100%",
    },
    actionBtn: {},
    message: {},
  }),
  {
    classNamePrefix: "Snackbar",
  }
);

export type SnackbarPropFactoryHelpers = Readonly<{
  readonly close: () => void;
}>;

export type SnackbarPropFactory<T> = T | ((helpers: SnackbarPropFactoryHelpers) => T);

export type SnackbarJSSClassKey = keyof ReturnType<typeof useStyles>;

export type SnackbarType = "default" | "error" | "warning" | "success";

export type SnackbarOptionsProps = {
  type?: SnackbarType;
  header?: SnackbarPropFactory<ReactNode>;
  message: SnackbarPropFactory<ReactNode>;
  uiActions?: SnackbarPropFactory<UIAction[]>;
  dismissible?: boolean;
  onView?: () => void;
  onUndo?: () => Promise<void> | void;
};

export type SnackbarProps = SnackbarOptionsProps & {
  classes?: Partial<ClassNameMap<SnackbarJSSClassKey>>;
  className?: string;
  onClose(): void;
};

function useResolvedFactory<T>(factory: SnackbarPropFactory<T>, helpers: SnackbarPropFactoryHelpers): T {
  return useMemo(() => {
    if (typeof factory === "function") return (factory as (helpers: SnackbarPropFactoryHelpers) => T)(helpers);
    else return factory;
  }, [factory, helpers]);
}

export const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (
    {
      className,
      classes: extClasses,
      onClose,
      type = "default",
      message,
      dismissible,
      onUndo,
      onView,
      header,
      uiActions,
    },
    ref
  ) => {
    const classes = useStyles({
      classes: extClasses,
    });

    const helpers = useMemo<SnackbarPropFactoryHelpers>(() => Object.freeze({ close: onClose }), [onClose]);

    header = useResolvedFactory(header, helpers);
    message = useResolvedFactory(message, helpers);
    uiActions = useResolvedFactory(uiActions, helpers);

    const colorClassMap = {
      [classes.defaultColors]: type === "default",
      [classes.errorColors]: type === "error",
      [classes.warningColors]: type === "warning",
      [classes.successColors]: type === "success",
    };

    return (
      <SnackbarContent ref={ref} className={clsx(classes.root, className, colorClassMap)}>
        {!!dismissible && (
          <IconButton aria-label="close" className={classes.close} onClick={onClose}>
            <CloseIcon className={clsx(classes.closeIcon, colorClassMap)} />
          </IconButton>
        )}
        <Box className={clsx(classes.content, { [classes.dismissContentPadding]: !!dismissible })}>
          {header ?? <Typography variant="h3">{header}</Typography>}
          <Box className={classes.message}>{message}</Box>
        </Box>
        <Box className={classes.actionsWrap}>
          {!!onUndo && (
            <Button className={clsx(classes.actionBtn, colorClassMap)} onClick={onUndo} size="small" color="primary">
              Undo
            </Button>
          )}
          {!!onView && (
            <Button className={clsx(classes.actionBtn, colorClassMap)} onClick={onUndo} size="small" color="primary">
              View
            </Button>
          )}
          {(uiActions || []).map((uiAction, i) => (
            <UIActionButton key={i} className={clsx(classes.actionBtn, colorClassMap)} uiAction={uiAction} />
          ))}
        </Box>
      </SnackbarContent>
    );
  }
);
