// eslint-disable-next-line no-restricted-imports
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { isValidElement, useCallback } from "react";
import { Snackbar, SnackbarOptionsProps } from "../components/Snackbar";

export type UseNotificationsReturnType = {
  sendNotification: (
    snackbarOptions: SnackbarOptionsProps | React.ReactElement | ((close: () => void) => React.ReactNode),
    notistackOptions?: OptionsObject
  ) => () => void;
};

export const useNotifications = (): UseNotificationsReturnType => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendNotification = useCallback<UseNotificationsReturnType["sendNotification"]>(
    (snackbarOptions, notistackOptions) => {
      let id: SnackbarKey;
      const handleClose = () => closeSnackbar(id);

      id = enqueueSnackbar("", {
        content: (() => {
          if (typeof snackbarOptions === "function") return snackbarOptions(handleClose);
          else if (isValidElement(snackbarOptions)) return snackbarOptions;
          else return <Snackbar onClose={handleClose} {...snackbarOptions} />;
        })(),
        ...notistackOptions,
      });

      return handleClose;
    },
    [closeSnackbar, enqueueSnackbar]
  );

  return { sendNotification };
};
