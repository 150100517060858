import { useCallback } from "react";
import { Link } from "../components/Link";
import { useAnalyticsContext } from "../context/AnalyticsContext";
import { SUPPORT_EMAIL } from "../utils/consts";
import { useNotifications } from "./useNotifications";

export const useOpenIntercom = (defaultMessage?: string) => {
  const analytics = useAnalyticsContext();
  const hasIntercom = typeof Intercom !== "undefined";

  const { sendNotification } = useNotifications();

  const openIntercom = useCallback(
    (message = defaultMessage) => {
      if (!hasIntercom || !analytics.state.intercom)
        sendNotification({
          message: (
            <>
              Couldn't load Intercom. Try sending us an email at{" "}
              <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>. Want to know more? Check out our{" "}
              <Link href="https://help.reclaim.ai/en/articles/6338330">help docs</Link>.
            </>
          ),
          type: "warning",
        });
      else analytics.state.intercom("showNewMessage", message);
    },
    [analytics.state, defaultMessage, hasIntercom, sendNotification]
  );

  const openIntercomStatic = useCallback(() => openIntercom(), [openIntercom]);

  return { openIntercom, openIntercomStatic, analytics };
};
